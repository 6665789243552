/**
 * Do not change! This file was autogenerated. This icon has been generated from a Figma design.
 *
 */
import { IconBase, IconBaseProps } from "react-icons";
import IconSvg from "./MediaIcon.svg";

export const MediaIcon = (props: IconBaseProps) => (
  <IconBase {...props}>
    {" "}
    <IconSvg />{" "}
  </IconBase>
);
