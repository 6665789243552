/**
 * Do not change! This file was autogenerated. This icon has been generated from a Figma design.
 *
 */
import { IconBase, IconBaseProps } from "react-icons";
import IconSvg from "./FloorPlanIcon.svg";

export const FloorPlanIcon = (props: IconBaseProps) => (
  <IconBase strokeWidth={1} {...props}>
    {" "}
    <IconSvg />{" "}
  </IconBase>
);
