/**
 * Do not change! This file was autogenerated. This icon has been generated from a Figma design.
 *
 */
import { IconBase, IconBaseProps } from "react-icons";
import IconSvg from "./PhoneIcon.svg";

export const PhoneIcon = (props: IconBaseProps) => (
  <IconBase {...props}>
    {" "}
    <IconSvg />{" "}
  </IconBase>
);
